.about {
    min-height: 60vh;
    margin-top: 5vh;
}

.about-info {
    width: 40%;
    margin: 0 auto;
    display: flex;
    flex: 1;
    flex-direction: row;
    line-height: 1.75em;
}

.about-img {
    height: 45vh;
}

.image-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 5vh;
}
