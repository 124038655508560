.block {
    width: 45vh;
    height: 40vh;
    background-color: lightgrey;
    margin: 3vh;
    overflow-y: auto;
    transition: 0.5s;
}

.grid {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    width: 80vw;
    margin: 0 auto;
    margin-top: 5vh;
}

.block:hover {
    opacity: 0.5
}
