.App {
  text-align: center;
}

body {
    font-family: 'Roboto Condensed';
    /* font-family: 'Cedarville Cursive', cursive; */
}

.title {
    font-family: 'Bad Script', cursive;
    /* font-family: 'Cedarville Cursive', cursive; */
    font-size: 2em;
}

.nav {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 25%;
    margin: 0 auto;
    justify-content: center;
}

.nav a {
    text-decoration: none;
    color: #444;
    transition: 0.2s;
    margin-right: 2.5vh;
    margin-bottom: 1.5vh;
}

.active {
    color: #9056fc !important;
}

a:hover {
    color: #9056fc;
}

.hr {
    width: 27%;
}

.social-logo {
    height: 4vh;
    min-height: 29px;
    min-width: 29px;
    margin: 2vh;
}

footer {
    bottom: 0px;
    left: 0px;
    right: 0px;
}
